import {
  gql,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  Observable,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuthState } from "@artifactlabs/react-auth";
import { useRouter } from "next/navigation";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables } from "@/gql/graphql";
import { useOpenModal } from "@/hooks/useModal";
import featureFlagsAtom from "@/recoil/featureFlags";

const GET_FEATURE_FLAGS = gql`
  query getFeatureFlags {
    featureFlags {
      checkoutFlow {
        mode
      }
      licenseOptions {
        commercial {
          mode
          enabled
        }
        personal {
          mode
          enabled
        }
        editorial {
          mode
          enabled
        }
        custom {
          mode
          enabled
          template
        }
      }
      showLicenseTooltip {
        enabled
      }
      checkoutOptions {
        stripe {
          enabled
          publishableKey
        }
      }
    }
  }
`;

const GQL_ENDPOINT = process.env.NEXT_PUBLIC_GQL_ENDPOINT;

export function updateContext(token: string, headers: any = {}) {
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
}

export default function Provider({
  children,
  tenantId,
}: {
  children: ReactNode;
  tenantId: string;
}): ReactElement {
  const { getAccessTokenWithoutChecking, isLoading } = useAuthState();
  const openModal = useOpenModal();
  const router = useRouter();

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: GQL_ENDPOINT,
    });

    const tenantIdLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          "x-tenant-id": tenantId,
        },
      };
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenWithoutChecking(false);
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    const getErrorLink = (errorCallback: Function) =>
      new ApolloLink((operation, forward) => {
        return new Observable(observer => {
          const observable = forward(operation);
          const subscription = observable.subscribe({
            next(value) {
              observer.next(value);
            },
            error(networkError) {
              errorCallback({ networkError, operation });
            },
            complete() {
              observer.complete();
            },
          });

          return () => subscription.unsubscribe();
        });
      });

    const chainedLink = ApolloLink.from([
      getErrorLink(() => {
        openModal("GENERIC_MODAL", {
          status: "error",
          title: "Something went wrong",
          description: "Oops, something went wrong. Please try again later!",
          mainActionText: "OK",
          onConfirm: () => router.refresh(),
        });
      }),
      authLink,
      tenantIdLink,
      httpLink,
    ]);

    return new ApolloClient({
      link: chainedLink,
      cache: new InMemoryCache({
        typePolicies: {
          PrefilledLicense: {
            keyFields: ["extId"],
          },
        },
      }),
      connectToDevTools: process.env.APP_ENV !== "production",
    });
  }, [getAccessTokenWithoutChecking, openModal, router, tenantId]);

  //
  const setFeatureFlagsState = useSetRecoilState(featureFlagsAtom);

  useEffect(() => {
    async function fetchFeatureFlags() {
      client
        .query<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>({
          query: GET_FEATURE_FLAGS,
          fetchPolicy: "cache-first",
        })
        .then(({ data, loading }) => {
          if (!loading && data?.featureFlags) {
            const { checkoutFlow, licenseOptions, showLicenseTooltip, checkoutOptions } =
              data.featureFlags;

            setFeatureFlagsState(curr => ({
              ...curr,
              checkoutFlow,
              licenseOptions,
              showLicenseTooltip,
              checkoutOptions,
            }));
          }
        });
    }

    if (!isLoading && tenantId) fetchFeatureFlags();
  }, [setFeatureFlagsState, client, isLoading, tenantId]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
