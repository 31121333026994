import { atom, selector } from "recoil";

type T_feature_options = {
  enabled: boolean;
  mode: string;
  template?: string | null | undefined;
};

type CheckoutOptions = {
  enabled: boolean;
  publishableKey?: string | null;
};

type T_feature = {
  enabled?: boolean;
  mode?: string;
  personal?: T_feature_options;
  editorial?: T_feature_options;
  commercial?: T_feature_options;
  custom?: T_feature_options;
  stripe?: CheckoutOptions;
};

export type FeatureFlagsState = Record<string, T_feature>;

const initialState: FeatureFlagsState = {};

const FeaturesFlagsAtom = atom({
  key: "FeaturesFlagsAtom",
  default: initialState,
});

export const getFeatureFlagSelector = selector({
  key: "getFeatureFlag",
  get: ({ get }) => {
    const featureFlags = get(FeaturesFlagsAtom);
    return (featureName: string) => featureFlags[featureName] ?? {};
  },
});

export default FeaturesFlagsAtom;
