import { atom } from "recoil";

interface ITenant {
  id: string | undefined;
  translation: any;
  settings: {
    withLogo: boolean;
    iconPath?: string;
    height?: number;
    width?: number;
  };
}

const defaultValue: ITenant = {
  id: undefined,
  translation: {},
  settings: {
    withLogo: true,
    iconPath: "",
  },
};

const tenantAtom = atom({
  key: "tenantAtom",
  default: defaultValue,
});

export default tenantAtom;
