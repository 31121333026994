
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { NextJsTracking, NextJsNoScriptTracking } from "@artifactlabs/shared-react-utils";
import { AnimatePresence } from "framer-motion";
import { NextComponentType } from "next";
import { AppProps } from "next/app";
import { AppContext, AppInitialProps } from "next/app";
import { useParams } from "next/navigation";
import { useMemo } from "react";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import { type NextPageWithLayout } from "./page";
import AppAuthenticationProvider from "@/components/Authentication/AppAuthenticationProvider";
import ApolloContextProvider from "@/components/Context/ApolloContextProvider";
import TenantProvider from "@/components/TenantProvider/TenantProvider";
import "@/styles/globals.css";
const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
interface AppPropsWithLayout extends AppProps {
    Component: NextPageWithLayout;
}
const MyApp: NextComponentType<AppContext, AppInitialProps, AppPropsWithLayout> = ({ Component, pageProps: { ...pageProps }, }: AppPropsWithLayout) => {
    const params = useParams();
    const subdomain = useMemo(() => (params?.subdomain as string) ?? "", [params]);
    const getLayout = Component.getLayout || (page => page);
    return (<>
      <NextJsNoScriptTracking gaMeasurementId={GA_MEASUREMENT_ID}/>
      <NextJsTracking gaMeasurementId={GA_MEASUREMENT_ID}/>
      <RecoilRoot>
        <TenantProvider tenantId={subdomain}>
          <AppAuthenticationProvider>
            <ApolloContextProvider tenantId={subdomain}>
              <AnimatePresence mode="wait">
                {/* <ServiceUnavailableBlocker> */}
                {getLayout(<Component {...pageProps}/>)}
                {/* </ServiceUnavailableBlocker> */}
              </AnimatePresence>
            </ApolloContextProvider>
          </AppAuthenticationProvider>
        </TenantProvider>
      </RecoilRoot>
    </>);
};
const __Next_Translate__Page__191eae287ad__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__191eae287ad__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  