import { useSetRecoilState } from "recoil";

import UIAtom from "@/recoil/UI";

export type MODAL_VIEWS =
  | "USER_PROFILE"
  | "REQUEST_PAYMENT"
  | "GENERIC_MODAL"
  | "CREATOR_SELECT"
  | "CHECKOUT_STRIPE";

export const useOpenModal = () => {
  const setUI = useSetRecoilState(UIAtom);
  return (view: MODAL_VIEWS = "GENERIC_MODAL", modalProps?: any, containerProps?: any) =>
    setUI(state => ({
      ...state,
      ...containerProps,
      modalView: view,
      displayModal: true,
      modalProps: modalProps ?? {},
    }));
};

export const useCloseModal = () => {
  const setUI = useSetRecoilState(UIAtom);
  return () =>
    setUI(state => ({
      ...state,
      displayModal: false,
      isClosable: true,
    }));
};

export const useSetModalView = () => {
  const setUI = useSetRecoilState(UIAtom);
  return (view: MODAL_VIEWS, modalProps?: any) =>
    setUI(state => ({
      ...state,
      modalView: view,
      displayModal: true,
      modalProps: modalProps ?? {},
    }));
};
